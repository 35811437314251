<template>
  <div
    class="app app-auth-forgot-password align-content-stretch d-flex flex-wrap justify-content-end"
  >
    <div class="app-auth-background hidden-on-mobile">&nbsp;</div>
    <div class="app-auth-container">
      <div class="logo">
        <a href="https://www.setxrm.com/" target="_blank">&nbsp;</a>
      </div>
      <p class="auth-description">
        {{
          $t(
            "PasswordChange.Title",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}<br />{{
          $t(
            "PasswordChange.Description",
            {},
            { locale: this.$store.state.activeLang }
          )
        }}
        <router-link :to="{ path: '/sign-in' }">{{
          $t("Buttons.Login", {}, { locale: this.$store.state.activeLang })
        }}</router-link>
      </p>
      <form
        class="form w-100 needs-validation"
        id="changePassword"
        @submit="changePassword"
        novalidate
        v-if="!isTokenUsed"
      >
        <div class="fv-row mb-3">
          <div>
            <label
              class="form-label fw-bolder text-dark fs-6 required"
              for="password"
            >
              {{
                $t(
                  "PasswordChange.Password",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </label>
            <div class="position-relative">
              <input
                class="form-control"
                type="password"
                placeholder=""
                name="password"
                id="password"
                required
                autocomplete="off"
                data-autofocus="true"
                v-model="userData.password"
                @keyup="validatePassword"
                @keyup.enter="changePassword"
              />
              <div class="invalid-tooltip">
                {{
                  this.$t(
                    "FieldErrors.TextNotValid",
                    {},
                    { locale: this.$store.state.activeLang }
                  )
                }}
              </div>
            </div>
          </div>
        </div>
        <div class="fv-row mb-3">
          <label
            class="form-label fw-bolder text-dark fs-6 required"
            for="password_confirmation"
            >{{
              $t(
                "PasswordChange.ConfirmPassword",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </label>
          <div class="position-relative">
            <input
              class="form-control"
              type="password"
              placeholder=""
              name="password_confirmation"
              id="password_confirmation"
              autocomplete="off"
              v-model="passwordConfirmation"
              @keyup="validatePassword"
              @keyup.enter="changePassword"
              required
            />
            <div class="invalid-tooltip">
              {{
                $t(
                  String.isNullOrWhiteSpace(passwordConfirmation)
                    ? "FieldErrors.TextNotValid"
                    : "PasswordChange.PasswordDontMatch",
                  {},
                  { locale: this.$store.state.activeLang }
                )
              }}
            </div>
          </div>
        </div>
        <div class="auth-submit">
          <button
            v-if="!formPosting"
            type="button"
            @click="changePassword"
            :disabled="formPosting"
            class="btn btn-primary"
          >
            {{
              $t(
                "Buttons.ForgotPassword",
                {},
                { locale: this.$store.state.activeLang }
              )
            }}
          </button>
          <div class="spinner-border text-primary" role="status" v-else>
            <span class="visually-hidden">Loading...</span>
          </div>
        </div>
      </form>
      <template v-else>
        <div class="alert alert-warning mb-0 fs-6">
          {{
            $t(
              "PasswordChange.FormHiddenMessage",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </div>
        <router-link to="/sign-in" class="btn btn-success mt-2 w-100">
          <i class="bi bi-lock-fill fs-5"></i>
          {{
            this.$t(
              "Buttons.Login",
              {},
              { locale: this.$store.state.activeLang }
            )
          }}
        </router-link>
      </template>
      <div class="divider mt-2"></div>
      <div class="d-flex justify-content-between align-items-center">
        <MembershipLocaleSwitcher />
        <SocialIcons />
      </div>
    </div>
  </div>
</template>
<script>
import Swal from "sweetalert2/dist/sweetalert2.min.js";
import $ from "jquery";

import SocialIcons from "@/components/layout/SocialIcons";
import MembershipLocaleSwitcher from "@/components/custom/locale-switchers/MembershipLocaleSwitcher.vue";
export default {
  components: {
    SocialIcons,
    MembershipLocaleSwitcher,
  },
  data() {
    return {
      userData: {
        clientUrl: `${window.location.origin}/#/`,
        token: encodeURIComponent(this.$route.query.token.replaceAll(" ", "+")),
        password: null,
      },
      passwordConfirmation: null,
      formPosting: false,
      passwordDontMatch: false,
      isTokenUsed: false,
    };
  },
  mounted() {
    let input = document.querySelector("[data-autofocus='true']");
    if (input) {
      input.focus();
    }

    if (String.isNullOrWhiteSpace(this.userData.token)) {
      this.$router.push({
        path: "/sign-in",
      });
    }
  },
  methods: {
    validatePassword() {
      this.passwordDontMatch =
        this.userData.password !== this.passwordConfirmation;
      if (this.passwordDontMatch) {
        $("#password_confirmation").addClass("is-invalid");
        $("#password_confirmation").removeClass("is-valid");
      } else {
        $("#password_confirmation").removeClass("is-invalid");
        $("#password_confirmation").addClass("is-valid");
      }
    },
    changePassword(event) {
      var form = $("form#changePassword"),
        self = this;
      form.addClass("was-validated");
      event.stopPropagation();
      event.preventDefault();
      this.isTokenUsed = false;

      if (
        !form[0].checkValidity() ||
        String.isNullOrWhiteSpace(this.userData.password) ||
        String.isNullOrWhiteSpace(this.passwordConfirmation) ||
        this.passwordDontMatch ||
        this.formPosting
      ) {
        if (this.passwordDontMatch) {
          this.validatePassword();
        }
        return;
      }

      this.formPosting = true;
      this.$authAxios
        .post("/PasswordChange", {
          clientUrl: this.userData.clientUrl,
          token: this.userData.token,
          password: this.$root.encrypt(this.userData.password, true),
        })
        .then((result) => {
          this.formPosting = false;
          var response = result.data,
            message = response.message;

          if (response.isOk) {
            message = this.$t(
              "PasswordChange.SuccessMessage",
              {},
              { locale: this.$store.state.activeLang }
            );
          } else {
            message = this.$root.getMessageByKey(message);
          }

          this.isTokenUsed = response.isOk;

          Swal.fire({
            html: `<span class='fw-bold fs-6'>${message}</span>`,
            icon: response.isOk ? "success" : "error",
            buttonsStyling: false,
            showDenyButton: response.isOk,
            denyButtonText:
              "<i class='bi bi-lock-fill fs-5'></i> " +
              this.$t(
                "Buttons.Login",
                {},
                { locale: this.$store.state.activeLang }
              ),
            confirmButtonText: this.$t(
              "Buttons.Close",
              {},
              { locale: this.$store.state.activeLang }
            ),
            customClass: {
              confirmButton: "btn fw-bold btn-success",
              denyButton: "btn fw-bold btn-info me-2",
            },
            reverseButtons: true,
          }).then((result) => {
            if (result.isDenied) {
              self.$router.push({ name: "Login" });
            }
          });
        });
    },
  },
  created() {
    this.$root.changeDocumentTitle(
      this.$t("PasswordChange.Title"),
      {},
      { locale: this.$store.state.activeLang }
    );
  },
};
</script>
